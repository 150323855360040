import React, { useState, useEffect, useContext } from "react"
import SiteContext from "../context/SiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { NewsGrid } from "../components/news"


export default props => {
  let data = props.data.craft.entries

  let context = useContext(SiteContext)


  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  let tags = props.data.craft.tags[0].tagsSubMenu

  return (
    <Layout>
      <SEO title="Work" />
      <NewsGrid data={data} filterData={tags}/>
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["news"]) {
        ... on Craft_news_article_Entry {
          uri
          title
          display
          slug
          date
          tags {
            title
          }
          header {
            ... on Craft_header_media_BlockType {
              image {
                url
                ... on Craft_images_Asset {
                  url
                  imageFile {
                    childImageSharp {
                      fluid(quality: 100, maxWidth: 1600) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              video {
                url
              }
            }
          }
        }
      }
      tags: entries(section: ["Menu"]) {
        ... on Craft_menu_menu_Entry {
          tagsSubMenu {
            col1
          }
        }
      }
    }
  }
`
