import React, { useEffect, useState, useRef } from 'react';

import styled from "styled-components";

var deburr = require('lodash/deburr');


let Container = styled.div`
    @media(min-width: 992px) {
        position: absolute;
        top: 67px; 
        left: 180px;
    }

    z-index: 999;

    .season-filters {
        display: flex;
        padding: 0.4rem 3rem 2rem 3rem;
        flex-wrap: wrap;
    }

    .season-filter {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex-basis: auto;
        margin-right: 1.5rem;
    }

    .season-filter:hover {
       text-decoration: underline;
    }

    .season-filter__label {
        margin-left: 5px;
        line-height: 1;
    }

    .season-filter--active  {
        text-decoration: underline;
    }

    @media(max-width: 767px) {
        .season-filters {
            flex-wrap: wrap;
            padding: 0 1.5rem 1rem 1.5rem;
        } 

        .season-filter {
            flex-basis: 100%;
            margin-bottom: 5px;
        }
    }
`

let sanitizeTag = (item) => {
    return deburr(item.toLowerCase().replace(/\s+/g, '-'));
}

export default function Component ({ data }) {
    let filtersRef = useRef();
    let [tags, setTags] = useState([]);


    useEffect(() => {

        let allTags = [];

        data.forEach((item, index) => {
            let obj = {
                selected: index === 0 ? true : false,
                tag: item.col1
            }

            allTags.push(obj)
        })

        setTags(allTags);    

    }, []);

    let toggleTag = (index) => {
        let unselectTags = tags

        unselectTags.forEach(item => item.selected = false)

        unselectTags[index].selected = true;

        setTags([...unselectTags])

        hideTiles(unselectTags[index].tag, index);
        
    }

    let hideTiles = (tag, index) => {

        let allEvents = document.querySelectorAll(".news-tile");

        allEvents.forEach(tile => {
            tile.classList.remove("hide-tile")
        })

        if(index === 0) return;


        allEvents.forEach(tile => {
            if(!tile.classList.contains(sanitizeTag(tag))) {
                tile.classList.add("hide-tile")
            }
        })
    }


    return (
        <Container ref={filtersRef}>
            <div class="season-filters">
                {tags.map((item, index) => (
                    <div key={item._id} 
                        class={tags[index]?.selected === true ? "season-filter season-filter--active" : "season-filter"} 
                        id={item.tag}
                        onClick={() => toggleTag(index)}
                        >
                        <h3 class="season-filter__label h3">{item.tag}</h3>
                    </div>
                ))}
            </div>
    </Container>       
    )
}