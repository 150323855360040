import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import NewsTile from "./news-tile"

import Filter from "./filter"

const Spacer = styled.div`
  padding-top: 10rem;
  background-color: #eeede9;

  @media(max-width: 767px) {
    padding-top: 7rem;
  }
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 1.5rem 6rem 1.5rem;
  background-color: #eeede9;

  @media (min-width: 767px) {
    padding: 0 3rem 6rem 3rem;
  }
`

export default props => {
  let data = props

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 1,
      distance: "30px",
    }).reveal(".news-tile")

    return () => {
      ScrollReveal().destroy()
    }
  }, [])

  return (
    <>
      <Spacer />
      <Filter data={props.filterData} />
      <Container>
        {data.data.map(data => {
          return data.display ?
          <NewsTile data={data} />
          :
          null
        })}
      </Container>
    </>
  )
}
